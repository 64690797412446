<!-- 用户审核详情页面 -->
<template>
    <div class="examine center-wrap">
        <div class="page">
            <h1 v-text="$t('examine.title')"></h1>
            <div class="write-box">
                <div>
                    <h2 v-text="$t('examine.reason')"></h2>
                    <div class="textarea-box">
                        <textarea disabled>资料不齐全</textarea>
                    </div>
                </div>
                <div>
                    <div class="title-box">
                        <img src="../assets/icon/asterisk.png">
                        <h2>后台配置字段1</h2>
                    </div>
                    <input type="text">
                </div>
                <div>
                    <h2>后台配置字段2</h2>
                    <input type="text">
                </div>
                <div>
                    <div class="title-box">
                        <img src="../assets/icon/asterisk.png">
                        <h2 v-text="$t('examine.upload')"></h2>
                    </div>
                    <div class="photo-box">
                        <div class="book" v-for="i in 6">
                            <img src="../assets/icon/photo.png">
                            <p class="click" v-text="$t('examine.del')"></p>
                        </div>
                        <!-- 添加图片 -->
                        <div class="add click"><img src="../assets/icon/add.png"></div>
                    </div>
                </div>
                <div class="submit click" v-text="$t('examine.submit')"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Examine',
    data() {
        return {}
    },
}
</script>
<style scoped>
.page {
    margin: 50px 283px 180px;
    width: 800px;
    height: 936px;
    border: 1px solid rgba(182, 182, 182, 0.7);
}

h1 {
    margin: 0;
    line-height: 50px;
    font-size: 16px;
    background: #F7F6F6;
    color: #999999;
    text-align: center;
}

.write-box {
    margin-left: 80px;
    margin-top: 50px;
}

.write-box>div {
    margin-bottom: 30px;
}

h2 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 16px;
    margin-bottom: 11px;
}

.textarea-box {
    height: 70px;
    width: 283px;
    padding: 10px 15px;
    background: rgba(250, 249, 249, 1);
}

.textarea-box textarea {
    height: 100%;
    width: 100%;
    background: rgba(250, 249, 249, 1);
    font-size: 12px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 12px;
}

input {
    height: 34px;
    width: 283px;
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 0.7);
    font-size: 12px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 12px;
    padding-left: 15px;
    padding-right: 15px;
}

.title-box {
    display: flex;
    align-items: center;
}

.title-box img {
    height: 11px;
    width: 11px;
    margin-bottom: 12px;
    margin-right: 5px;
}

.photo-box {
    display: flex;
    flex-wrap: wrap;
}

.book {
    height: 109px;
    width: 109px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
}

.book p {
    height: 24px;
    width: 109px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 24px;
    background: rgba(153, 153, 153, 0.80);
    text-align: center;
    position: absolute;
    bottom: 0;
}

.book img {
    height: 109px;
    width: 109px;
}

.add {
    height: 110px;
    width: 110px;
    border: 1px solid rgba(204, 204, 204, 0.7);
    border-radius: 2px;
    position: relative;
    margin-bottom: 20px;
}

.add img {
    height: 28px;
    width: 28px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.submit {
    height: 42px;
    width: 153px;
    margin: 33px 0px 0px 244px;
    background: #297FAB;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 42px;
    color: #FFFFFF;
    border-radius: 4px;
}
</style>